import * as styles from './style.css'
import { BaseTag, type BaseTagProps } from '~/components/ui/tags/BaseTag'

type Props = {
  className?: string
  infos: BaseTagProps[]
  isNotSemantic?: boolean
}

export const BaseTagList = ({ className, infos, isNotSemantic }: Props) => {
  const Wrapper = isNotSemantic ? 'span' : 'div'
  const Items = isNotSemantic ? 'span' : 'ul'
  const Item = isNotSemantic ? 'span' : 'li'

  return (
    <Wrapper className={[styles.baseTagList, className].filter(Boolean).join(' ')}>
      <Items className={styles.baseTagList_items}>
        {infos.map((info, i) => (
          <Item key={i} className={styles.baseTagList_item}>
            <BaseTag {...info} />
          </Item>
        ))}
      </Items>
    </Wrapper>
  )
}
