import * as styles from './style.css'

export type BaseTagProps = {
  className?: string
  name: string
  color?: 'sub' | 'primary'
}

export const BaseTag = ({ className, name, color = 'sub' }: BaseTagProps) => {
  return (
    <span className={[styles.baseTag, styles[`baseTag__${color}`], className].filter(Boolean).join(' ')}>
      <span className={styles.baseTag_text}>{name}</span>
    </span>
  )
}
