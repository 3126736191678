import { type ReactNode } from 'react'
import * as styles from './style.css'

type Props = {
  className?: string
  title: ReactNode
  titleTag?: keyof JSX.IntrinsicElements
}

export const MainTitle = ({ className, title, titleTag }: Props) => {
  const Title = titleTag ?? 'h1'

  return (
    <div className={[styles.mainTitle, className].filter(Boolean).join(' ')}>
      <Title className={styles.mainTitle_text}>{title}</Title>
    </div>
  )
}
