import { type ReactNode, useMemo } from 'react'
import * as styles from './style.css'
import { BaseButton, type BaseButtonProps } from '~/components/ui/buttons/BaseButton'
import { type BaseTagProps } from '~/components/ui/tags/BaseTag'
import { BaseTagList } from '~/components/ui/lists/BaseTagList'

export type EventCardProps = {
  className?: string
  color?: 'dark' | 'primary'
  tagInfos?: BaseTagProps[]
  featureTitle?: ReactNode
  featureTitleTag?: string
  title: ReactNode
  titleTag?: keyof JSX.IntrinsicElements
  description?: ReactNode
  bottomButtonInfo?: BaseButtonProps
  backgroundImage?: {
    src: string
    alt?: string
  }
}

export const EventCard = ({
  className,
  color = 'dark',
  tagInfos,
  featureTitle,
  featureTitleTag,
  title,
  titleTag,
  description,
  bottomButtonInfo,
  backgroundImage,
}: EventCardProps) => {
  const Title = titleTag ?? 'h3'
  const eventTagInfos: BaseTagProps[] = useMemo(() => {
    if (!tagInfos || tagInfos.length <= 0) {
      return []
    }

    return tagInfos.map((info) => {
      return {
        color: 'primary',
        ...info,
      }
    })
  }, [tagInfos])

  return (
    <div className={[styles.eventCard, styles[`eventCard__${color}`], className].filter(Boolean).join(' ')}>
      <div className={styles.eventCard_wrapper}>
        <div className={styles.eventCard_container}>
          {eventTagInfos.length > 0 && (
            <div className={styles.eventCardTags}>
              <BaseTagList infos={eventTagInfos} className={styles.eventCardTags_list} />
            </div>
          )}

          {featureTitle && (
            <div className={styles.eventCardFeatureTitle}>
              <div className={styles.eventCardFeatureTitle_container}>
                {featureTitleTag && <span className={styles.eventCardFeatureTitle_tag}>{featureTitleTag}</span>}

                <span className={styles.eventCardFeatureTitle_title}>{featureTitle}</span>
              </div>
            </div>
          )}

          <div className={styles.eventCardTitle}>
            <Title className={styles.eventCardTitle_text}>{title}</Title>
          </div>

          {description && (
            <div className={styles.eventCardDescription}>
              <p className={styles.eventCardDescription_paragraph}>{description}</p>
            </div>
          )}

          {bottomButtonInfo && (
            <div className={styles.eventCardBottomButton}>
              <BaseButton {...bottomButtonInfo} className={styles.eventCardBottomButton_button} />
            </div>
          )}
        </div>
      </div>

      {backgroundImage && (
        <figure className={styles.eventCardBackground}>
          <img src={backgroundImage.src} alt={backgroundImage.alt} className={styles.eventCardBackground_image} />
        </figure>
      )}
    </div>
  )
}
