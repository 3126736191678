import * as styles from './style.css'
import { EventCard, type EventCardProps } from '~/components/ui/cards/EventCard'

type Props = {
  className?: string
  infos: EventCardProps[]
  col?: '1' | '2'
}

export const EventCardList = ({ className, infos, col = '1' }: Props) => {
  return (
    <div className={[styles.eventCardList, styles[`eventCardList__col${col}`], className].filter(Boolean).join(' ')}>
      <ul className={styles.eventCardList_items}>
        {infos.map((info, i) => (
          <li key={i} className={styles.eventCardList_item}>
            <EventCard {...info} />
          </li>
        ))}
      </ul>
    </div>
  )
}
